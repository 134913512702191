<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" width="238" height="107" />
      </b-link>
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto auth-body">
          <b-card-title
            class="mb-0 font-weight-bold fs-36 lh-45 fw-600 basic-white login-title"
            title-tag="h2"
          >
            ¡Bienvenido!
          </b-card-title>
          <b-card-text class="mb-3 basic-white fs-16 lh-20">
            Por favor inicia sesión en tu cuenta
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Correo electrónico" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="correo"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger login-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Contraseña"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger login-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="fs-16 lh-20 basic-white p-0"
                  block
                  :disabled="invalid"
                >
                  Ingresar
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 auth-text">
            <span class="basic-white fs-12 lh-15">¿Nuevo en nuestra plataforma?</span>
            <b-link :to="{ name: 'register' }">
              <span class="fs-12 lh-15">&nbsp;Crea una cuenta</span>
            </b-link>
          </b-card-text>
          <b-button
            v-if="shown"
            variant="primary"
            class="fs-16 lh-20 basic-white p-0 mt-3 app-btn"
            @click="installPWA"
          >
            Instalar {{ appName }}
          </b-button>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import Push from "push.js";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
localize("es", es);

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      password: null,
      userEmail: null,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      loading: false,
      shown: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    loginBgd() {
      return require("@/assets/images/pages/login/login_background.png");
    },
  },
  mounted() {
    this.notifications();
  },
  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },
  methods: {
    async notifications() {
      const notifications = await this.$store.dispatch("notifications/index", {
        params: { public: 1 },
      });
      notifications.forEach((item) => {
        const res = {
          ...item,
          onClose: async () => {
            await this.$store
              .dispatch("notifications/close", { notificationId: item.id })
              .then(async () => {});
          },
        };
        Push.create("Andino App", res);
      });
    },
    async login() {
      try {
        this.loading = true;
        const valid = await this.$refs.loginForm.validate();
        if (!valid) return;

        await this.$store.dispatch("user/login", {
          email: this.userEmail,
          password: this.password,
        });
        // this.$router.push({ name: 'dashboard' })
        window.location.href = "/";
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response.data.message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    copyCredential({ email, password }) {
      this.userEmail = email;
      this.password = password;
    },
    dismissPrompt() {
      this.shown = false;
    },
    installPWA() {
      if (this.installEvent) {
        this.installEvent.prompt();
        this.installEvent.userChoice.then((choice) => {
          this.dismissPrompt(); // Hide the prompt once the user has made a choice
          if (choice.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/page-auth.scss";
</style>
